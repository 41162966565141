import consumer from "./consumer";

consumer.subscriptions.create("FileImportNotificationChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    const statuses = {
      processed: "Procesado",
      discard: "Descartado",
      processing: "Procesando",
      assigned: "Stock asignados",
      pending: "Pendiente",
      halted: "Interrumpido",
      annulled: "Anulado",
      processed_with_errors: "Procesado con errores",
      canceled: "Cancelado",
      finished: "Finalizado",
      sending_to_tms: "Enviando a TMS",
      get_working_days: "Obteniendo Jornadas",
      test_sending_to_tms: "Enviando a TMS",
      test_get_working_days: "Obteniendo Jornadas",
      get_sending_to_tms: "Enviando a TMS",
      prod_get_working_days: "Obteniendo Jornadas",
      assigned_checked: ' con stock reservado'
    };
    $("#" + data.env + "_file-import-" + data.file.id + "-status").text(
      statuses[data.env + "_" + data.file.status]
    );
    if (data.label) {
      toastr.success(data.label);
    } else if (data.file.status != "halted") {
      toastr.success("Archivo " + statuses[data.file.status]);
    } else {
      toastr.warning("Archivo " + statuses[data.file.status]);
    }
    if (data.file.status == "finished") {
      window.localStorage.removeItem(
        data.env + "_progress_bar_" + data.process + "_" + data.file.id
      );
    }
    window.location.reload();
  },
});
