// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("channels");

require("bootstrap");
require("jquery");
require("jquery.easing");
require("jquery-ui-sortable");

require("selectize");
require("select2");
require("select2/dist/js/i18n/es");
require("select2/dist/js/i18n/en");
require("air-datepicker");
require("air-datepicker/src/js/i18n/datepicker.en");
require("air-datepicker/src/js/i18n/datepicker.es");

window.toastr = require("toastr");
let locale;

import "@fortawesome/fontawesome-free/js/all";
import "../stylesheets/backoffice";

function basicFormListener(forms) {
  if (forms) {
    Array.prototype.filter.call(forms, function (form) {
      form.addEventListener(
        "submit",
        function (event) {
          if (form.checkValidity() == false) {
            event.preventDefault();
            event.stopPropagation();
          }
          form.classList.add("was-validated");
        },
        false
      );
    });
  }
}

window.addEventListener("load", function () {
  // Global locale
  locale = $("body").attr("data-locale");

  // Backoffice Theme settings
  import("./theme/sb-admin-2");

  // Jquery initializers
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();

  // Form initialize Boostra4 form validations
  const productForms = document.querySelectorAll(
    'form.needs-validation[id*="_product"]'
  );
  const fileImportForms = document.querySelectorAll(
    'form.needs-validation[id*="_file_import"]'
  );
  const warehouseForms = document.querySelectorAll(
    'form.needs-validation[id*="_warehouse"]'
  );
  const userForms = document.querySelectorAll(
    'form.needs-validation[id*="_user"]'
  );
  const carrierForms = document.querySelectorAll(
    'form.needs-validation[id*="_carrier"]'
  );
  const segmentForms = document.querySelectorAll(
    'form.needs-validation[id*="_segment"]'
  );
  const packageForms = document.querySelectorAll(
    'form.needs-validation[id*="_package"]'
  );
  const stackableGroupForms = document.querySelectorAll(
    'form.needs-validation[id*="_stackable_group"]'
  );
  const platformForms = document.querySelectorAll(
    'form.needs-validation[id*="_platform"]'
  );
  const workingHourForms = document.querySelectorAll(
    'form.needs-validation[id*="_working_hour"]'
  );
  const fareForms = document.querySelectorAll(
    'form.needs-validation[id*="_fare"]'
  );
  const vehicleForms = document.querySelectorAll(
    'form.needs-validation[id*="_vehicle"]'
  );
  const driverForms = document.querySelectorAll(
    'form.needs-validation[id*="_driver"]'
  );
  basicFormListener(productForms);
  basicFormListener(fileImportForms);
  basicFormListener(warehouseForms);
  basicFormListener(userForms);
  basicFormListener(carrierForms);
  basicFormListener(segmentForms);
  basicFormListener(packageForms);
  basicFormListener(stackableGroupForms);
  basicFormListener(platformForms);
  basicFormListener(workingHourForms);
  basicFormListener(fareForms);
  basicFormListener(vehicleForms);
  basicFormListener(driverForms);

  // Select2 Core settings
  $.fn.select2.defaults.set("theme", "bootstrap4");
  $.fn.select2.defaults.set("language", locale);
  $.fn.select2.defaults.set("scrollAfterSelect", true);
  $.fn.select2.defaults.set("allowClear", true);
  $.fn.select2.defaults.set("placeholder", "");

  $("select.form-control.select2").select2();
  $("select.form-control.selectize").selectize({
    plugins: ["remove_button"],
    delimiter: '|',
    maxOptions: 100,
    persist: true,
    preload: true
  });

  // Air-datepicker initializers
  $(".air-datepicker").datepicker({
    dateFormat: "yyyy/mm/dd",
    clearButton: true,
    language: locale,
  });

  $(".air-timepicker").datepicker({
    timeFormat: "hh:ii",
    timepicker: true,
    onlyTimepicker: true,
    clearButton: true,
    language: locale,
  });

  $('input[class*="custom-file-input"]').change(function (e) {
    const files = this.files;
    console.log(this.id);
    const oldName = document
      .querySelector(`label[for="${this.id}"]`)
      .getAttribute("data-text");
    var fileName = [];
    for (var i = 0; i < files.length; i++) {
      fileName.push(files[i].name);
    }
    if (files.length == 0) {
      fileName = [oldName];
    }
    var nextSibling = e.target.nextElementSibling;
    nextSibling.innerText = fileName.join(", ");
  });

  // Confirmable modal - button delete
  $('a[id*="delete-"]').click(function (e) {
    e.preventDefault();
    e.stopPropagation();
    const action = $(this).attr("data-action");
    const target = $(this).attr("id").split("-")[1];
    $(`#delete-${target}-modal`)
      .on("shown.bs.modal", function () {
        $(`#delete-${target}-modal form`).attr("action", action);
      })
      .modal();
  });

  // Enable/Disable password container
  $('select[class="custom-select"]#user_interface').change(function () {
    const value = $(this).val();
    if (value === "api") {
      $("div#backoffice-container").addClass("d-none");
      $("div#api-container").removeClass("d-none");
      $("input#user_first_name").attr("required", true);
      $("select#user_role").attr("required", false);
      $("input#user_email").attr("required", false);
    } else {
      $("div#backoffice-container").removeClass("d-none");
      $("div#api-container").addClass("d-none");
      $("input#user_first_name").attr("required", false);
      $("select#user_role").attr("required", true);
      $("input#user_email").attr("required", true);
    }
  });
  $('.draggable').sortable({
    start: function(event, ui) {
      ui.item.startPos = ui.item.index()
    },
    stop: function(event, ui) {
      const url = window.location.origin + '/backoffice/priorities/sortable_update'
      const newPosition= ui.item.index()
      const startPos = ui.item.startPos
      const params = { priorities: {} }
      if(startPos < newPosition) {
        var oldValue = $('.draggable').children().eq(newPosition).children().eq(4).text()
        for(var i = startPos; i <= newPosition; i++){
          const id = $('.draggable').children().eq(i).attr('id').split('-')[0]
          params.priorities[id] = oldValue
          $('.draggable').children().eq(i).children().eq(4).text(oldValue)
          oldValue--
        }
      }else if(startPos > newPosition){
        var oldValue = $('.draggable').children().eq(newPosition).children().eq(4).text()
        for(var i = startPos; i >= newPosition; i--){
          const id = $('.draggable').children().eq(i).attr('id').split('-')[0]
          params.priorities[id] = oldValue
          $('.draggable').children().eq(i).children().eq(4).text(oldValue)
          oldValue++
        }
      }
      $.ajax({
        url: url,
        method: 'PUT',
        contentType: 'application/json',
        data: JSON.stringify(params)
      })
    }
  })
});

// Enabling external user for js.erb files
import JQuery from "jquery";
window.$ = window.JQuery = JQuery;

// Toaster config
toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: true,
  progressBar: true,
  positionClass: "toast-top-right",
  preventDuplicates: true,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "5000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
};
